<template>
    <div class="content-wrapper">
        <div class="items">
            <div class="container mt-5">
                <div class="row justify-content-center">
                    <div class="col-md-12">
                        
                        <div class="card">
                            <div class="card-header">
                                Supplier
                            </div>
                            <!-- {{ name }} -->
                            <div class="card-body">
                                <router-link :class="['btn btn-md btn-success mb-2']" :to="menu+'/create'">Tambah Supplier</router-link> 
                                <hr>  
    <!-- {{ items }} -->
                                <div class="table-responsive mt-2">
                                    <table class="table table-hover table-bordered">
                                        <thead>
                                        <tr>
                                            <th>KODE</th>
                                            <th>NAMA</th>
                                            <th>KET</th>
                                            <th>AKSI</th>
                                        </tr>
                                        </thead>
                                        <tbody> 
                                        <!-- {{ menu }} halo -->
                                        <tr v-for="item in items" :key="item.id">
                                            <td>{{ item.supp_code }}</td>
                                            <td>{{ item.supp_name }}</td>
                                            <td>{{ item.supp_desc }}</td>
                                            <td class="text-center">
                                                <!-- <router-link :to="{name: 'edit', params: { id: item.id }}" class="btn btn-sm btn-primary mr-2">EDIT</router-link> -->
                                                <router-link class="btn btn-sm btn-primary mr-2" :to="'/set_supplier/edit/' + item.id ">Edit</router-link>
                                                <button @click.prevent="itemDelete(item.id)" class="btn btn-sm btn-danger">HAPUS</button>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</template>


<script>
    //import axios from 'axios'

    export default {

        data() {
            return {
                items: [],
                name : this.$route.name,
                menu : this.$route.meta.menu,
                path : this.$route.path,
            }
        },
        created: async function() {
            console.log('created');
             
            console.log(this.apiGets);
            //console.log(this.apiUrl);
            this.isLoading = true;
            const params = { 
                page: 's',
                limit: 10,
            };
            var response = await this.apiGets(`suppliers`, { params });
            if(response.status == 200) {
                //this.lists = { ...response.data.data };
                this.items = { ...response.data.data };
            }

			this.isLoading = false;
            
        },
         
         methods: {
            async getServices(route = this.$route) {
                this.isLoading = true;
                console.log(route);
                const params = { 
                    page: 's',
                    limit: 10,
                };
                var response = await this.apiGets(`suppliers`, { params });
                if(response.status == 200) {
                    //this.lists = { ...response.data.data };
                    this.items = { ...response.data.data };
                }

                this.isLoading = false;
            },
            itemDelete(id)
            {
/*                  this.$swal({
                                title: 'Error',
                                html: msg,
                                border: '1px solid #F0E1A1',
                                backdrop: "rgba(43, 165, 137, 0.45)",
                            
                            });
                 */
                this.$swal({
                    title: "Anda Yakin Akan Menghapus data?",            
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'Ya',
                    cancelButtonColor: '#3085d6',
                    cancelButtonText: "Batal"
                
                }).then( async  result => {
                    if (result.isConfirmed) {
                        this.isLoading = true;
                        var response = await this.apiDeletes(`suppliers/${id}`);
                        if(response.status == 200) {
                            this.items = { ...response.data.data };
                        }
                        this.getServices();

                        this.isLoading = false;
                        console.log(result);
                    }                        
                    console.log(id);
          
                 /*    this.isLoading = true;
                    var response = await this.apiDeletes(`suppliers/${id}`);
                    if(response.status == 200) {
                        this.items = { ...response.data.data };
                    }
                    this.getServices();

                    this.isLoading = false; */

                });
                   /*  this.isLoading = true;
                    var response = await this.apiDeletes(`suppliers/${id}`);
                    if(response.status == 200) {
                        this.items = { ...response.data.data };
                    }
                    this.getServices();

                    this.isLoading = false; */

            }
        }
    }
</script>